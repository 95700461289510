import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import Logo from '../assets/logo.png'

function AdminDataCreate() {
    const navigate = useNavigate();
    const [image, setImage] = useState('');
    const [category, setCategory] = useState('');
    const [title, setTitle] = useState('');
    const [description_0, setDescription_0] = useState('');
    const [description_1, setDescription_1] = useState('');
    const [description_2, setDescription_2] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault()
        // console.log(image, category, title, description);
        // axios.post('http://localhost:5000/storing-data', {
        axios.post('https://plum-combative-perch.cyclic.app/storing-data', {
            image: image,
            category: category,
            title: title,
            description_0: description_0,
            description_1: description_1,
            description_2: description_2,
        })
            .then(res => {
                // console.log(res.data)
            }).catch(err => {
                // console.log(err);
            })
        alert("News has been added successfully..!");
        // navigate('/add-news')
        window.location.reload()

    }

    function sayHello() {
        // alert('Hello!');
        navigate('/')
    }

    function convertToBase64(e) {
        // console.log(e);
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
            // console.log(reader.result); //base64encoded string
            setImage(reader.result)
        };
        reader.onerror = error => {
            // console.log("Error", error);
        };
    }

    return (
        <>
            <div className="card">
                <div className="admin" style={{ margin: '10px' }}>
                    <Link className="admin-link" style={{ textAlign: 'right', display: 'block', marginTop: '5px' }} to={'/'}>
                        <img className="admin-img" src={Logo} style={{ width: '150px' , marginTop:'-30px'}} />
                    </Link>
                </div>
                <span className="font" style={{ marginTop: '7px', fontSize: '30px', color: '#044382' }}>Hello  ' {localStorage.getItem('Name')} '</span>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                        localStorage.clear();
                        alert('See you soon Admin, Bye 😊')
                        navigate('/sign-in')
                    }}
                >Logout</Button>
            </div>
            <Button
                variant="outlined"
                color="error"
                className="admi-but"
                style={{
                    marginTop: '50px',
                    marginLeft: '100px'
                }}
                onClick={sayHello}>
                Cancel / Go Back
            </Button>
            <h1 className="center" style={{ color: '#044382' }}>Add News</h1>
            <div className="outcard">
                <label>Categories</label><br />
                <input
                    required
                    onChange={(e) => {
                        setCategory(e.target.value)
                    }}
                    value={category}
                    className="inputs"
                    type="text"
                /><br />
                <label>Title</label><br />
                <input
                    required
                    onChange={(e) => {
                        setTitle(e.target.value)
                    }}
                    value={title}
                    className="inputs"
                    type="text"
                /><br />
                <label>Description Paragraph 1</label><br />
                <textarea
                    required
                    value={description_0}
                    onChange={(e) => {
                        setDescription_0(e.target.value)
                    }}
                    className="inputs"
                /><br />
                <label>Description Paragraph 2</label><br />
                <textarea
                    // required
                    value={description_1}
                    onChange={(e) => {
                        setDescription_1(e.target.value)
                    }}
                    className="inputs"
                /><br />
                <label>Description Paragraph 3</label><br />
                <textarea
                    // required
                    value={description_2}
                    onChange={(e) => {
                        setDescription_2(e.target.value)
                    }}
                    className="inputs"
                /><br />
                <label>Upload Image</label><br />
                <input
                    required
                    className="inputs"
                    type="file"
                    accept="image/*"
                    onChange={convertToBase64}
                />
                <br />
                {image == "" || image == null ? "" : <img style={{ marginLeft: "270px" }} width={150} height={110} src={image} />}
                <br /><br />
                <button
                    onClick={handleSubmit}
                    className="btns"
                >ADD</button>
                <br /><br />
            </div>
        </>
    )
}

export default AdminDataCreate
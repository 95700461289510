import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";

export default function ForgetPassword() {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const handleSubmit = () => {
        console.log(email);
        // axios.post('http://localhost:5000/send-otp', {
        axios.post('https://plum-combative-perch.cyclic.app/send-otp', {
            email: email,
        }).then(res => {
            console.log(res.data)
            if (res.data.code === 200) {
                navigate('/otp');
            }
            if (res.data.code === 500) {
                alert('User not found');
            }
        }).catch(err => {
            console.log(err);
        })
    }
    return (<>
        <h1 className="center">Enter your Email </h1>
        <div className="outcard">
            <label>Email:</label><br />
            <input
                value={email}
                onChange={(e) => {
                    setEmail(e.target.value)
                }}
                className="inputs"
                type="email"
            />
            <br /><br />
            <button onClick={handleSubmit} className="btns"> SEND OTP </button>
        </div >
    </>
    )
}
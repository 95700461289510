import React from "react";
import useFetch from "./useFetch.js";
import { Link ,useParams, useNavigate } from "react-router-dom";
import CreateIcon from '@mui/icons-material/Create';
import { Button } from "@mui/material";
import './Read.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Read = () => {
    const navigate = useNavigate();

    const { id } = useParams();
    // const { data: news, isPending, error } = useFetch('http://localhost:5000/show-data/' + id)
    const { data: news, isPending, error } = useFetch('https://plum-combative-perch.cyclic.app/show-data/' + id)
    return (
        <>
            <div>
                {/* {isPending && <div style={{ color: "white" }}><h1>loading News...</h1></div>}
                {error && <div style={{ color: "white", marginTop: '100px' }}><h1>{error}</h1></div>} */}
                {news && (
                    <article>
                        {/* <h2>{blog.title}</h2> */}
                        <img className="blog-img" src={news.image} alt="Banner1" />
                        <div className="content2">
                            <p></p>
                            <h1 className="blog-title">
                                {news.title}
                            </h1>
                        </div>
                        <div className="blog-div" style={{ marginTop: "30px", alignItems: "center" }}>
                            <div>
                                <div className="blog-card">
                                    <div className="blog-text">
                                    <p>
                                        {news.description_0}
                                    </p>
                                    <br></br>
                                    <p>
                                        {news.description_1}
                                    </p>
                                    <br></br>
                                    <p>
                                        {news.description_2}
                                    </p>
                                    <br></br>
                                   
                                    <Link to={`/`}>
                                    <ArrowBackIcon style={{ fontSize: '30px', color: "white"}}/>
                                    </Link>
                                    <Link to={`/update/${news._id}`}>
                                    {/* <Button color="success">Edit</Button> */}
                                        <CreateIcon style={{ fontSize: '30px', color: "white", marginLeft: '10px' }} />
                                    </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                )}
            </div>
        </>
    );
};

export default Read;

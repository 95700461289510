import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Signup } from './components/Signup';
import { Signin } from './components/Signin';
import { Home } from './components/Home';
import ForgetPassword from './components/ForgetPassword';
import NewSubmit from './components/NewSubmit';
import AdminDataCreate from './components/AdminDataCreate';
import Update from './components/Update';
import Delete from './components/Delete';
import Read from './components/Read';
function App() {
  return (
    <div>
      <Router>
        <Routes>
          {/* <Route path='/sign-up' element={<Signup />} /> */}
          <Route path='/sign-in' element={<Signin />} />
          <Route path='/forget-password' element={<ForgetPassword />} />
          <Route path='/otp' element={<NewSubmit />} />
          <Route path='/' element={<Home />} />
          <Route path='/add-news' element={<AdminDataCreate />} />
          <Route path='/update/:id' element={<Update />} />
          <Route path='/read/:id' element={<Read/>} />
          <Route path='/delete' element={<Delete />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

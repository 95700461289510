import { useEffect, useState } from "react";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link, useNavigate } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.info.dark,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 20,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function ShowData() {
    const navigate = useNavigate();
    const [user, setUser] = useState([]);

    function deletebtn(id, category) {
        // alert('do you want to delete this row?')
        if (window.confirm(`Are you sure you want to delete this row with id:  ${id}  ?`)) {
            // fetch("http://localhost:5000/delete", {
            fetch("https://plum-combative-perch.cyclic.app/delete", {
                method: "POST",
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Access-Control-Allow-Origin": "*"
                },
                body: JSON.stringify({
                    newsid: id,
                })
            })
                .then((response) => response.json())
                .then((data) => alert(data.data));
                // navigate('/')
            window.location.reload()
        } else {
            alert(`can not delete ${id} !, Somthing went wrong Please try again..!`)
        }

    }

    const fetchData = () => {
        // return fetch("http://localhost:5000/show-data")
        return fetch("https://plum-combative-perch.cyclic.app/show-data")
            .then((response) => response.json())
            .then((data) => setUser(data.allTasks));
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead >
                    <TableRow>
                        <StyledTableCell style={{ fontSize: '25px' }} align="left" width={2}>ID</StyledTableCell>
                        <StyledTableCell style={{ fontSize: '25px' }} align="left" width={10}>Category</StyledTableCell>
                        <StyledTableCell style={{ fontSize: '25px' }} align="left" width={10}>Title</StyledTableCell>
                        <StyledTableCell style={{ fontSize: '25px' }} align="left" width={10}>Description Paragraph 1</StyledTableCell>
                        <StyledTableCell style={{ fontSize: '25px' }} align="left" width={10}>Description Paragraph 2</StyledTableCell>
                        <StyledTableCell style={{ fontSize: '25px' }} align="left" width={10}>Description Paragraph 3</StyledTableCell>
                        <StyledTableCell style={{ fontSize: '25px' }} align="center" width={10}>Image</StyledTableCell>
                        <StyledTableCell style={{ fontSize: '25px' }} align="center" width={200}>Actions</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {user && user.length > 0 && user.map((userObj, index) => (
                        <StyledTableRow key={userObj._id}>
                            <StyledTableCell width={2} component="th" scope="row">
                                {userObj._id}
                            </StyledTableCell>
                            <StyledTableCell width={5} height={10} align="left">{userObj.category}</StyledTableCell>
                            <StyledTableCell width={5} height={10} align="left">{userObj.title}</StyledTableCell>
                            <StyledTableCell width={10} height={4} align="left">{userObj.description_0.substring(0, 150)}</StyledTableCell>
                            <StyledTableCell width={10} height={4} align="left">{userObj.description_1.substring(0, 150)}</StyledTableCell>
                            <StyledTableCell width={10} height={4} align="left">{userObj.description_2.substring(0, 150)}</StyledTableCell>
                            <StyledTableCell width={5} height={10} align="center"><img width={180} height={150} src={userObj.image} /></StyledTableCell>
                            <StyledTableCell width={20} height={10} align="center">
                                {/* <Button color="success" onClick={updatebtn}>Update</Button>&nbsp;&nbsp; */}
                                {/* <Button color="error" onClick={deletebtn}>Delete</Button> */}
                                <Link to={`/read/${userObj._id}`}>
                                {/* <Link to={`/read`}> */}
                                    <RemoveRedEyeIcon style={{ fontSize: '40px', color: "#01579b" }} />
                                </Link>
                                <Link to={`/update/${userObj._id}`}>
                                    <CreateIcon style={{ fontSize: '40px', color: "#01579b", marginLeft: '10px' }} />
                                </Link>
                                <DeleteIcon style={{ fontSize: '40px', color: "red", marginLeft: '10px' }} onClick={() => deletebtn(userObj._id, userObj.category)} />
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
export default ShowData
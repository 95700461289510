import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ShowData from "./ShowData";
import { Button } from "@mui/material";
import Logo from '../assets/logo.png'

export function Home() {
    const navigate = useNavigate();
    useEffect(() => {
        const token = localStorage.getItem('TOKEN');
        if (!token) {
            navigate('/sign-in')
        }
    }, [])
    function sayHello() {
        // alert('Hello!');
        navigate('/add-news')
    }
    return (
        <>
            <div className="card">
                <div className="home-dv" style={{ margin: '10px' }}>
                    <Link className="home-tex" style={{ textAlign: 'right', display: 'block', marginTop: '5px' }} to={'/'}>
                        <img className="home-logo" src={Logo} style={{ width: '150px', marginTop:'-30px' }} />
                    </Link>
                </div>
                <span className="home-sp" style={{ marginTop: '7px', fontSize: '30px', color: '#044382' }}>Hello  ' {localStorage.getItem('Name')} '</span>
                {/* <span style={{ marginTop: '30px' }}>Hello Admin:  {localStorage.getItem('Name')} Admin Email: {localStorage.getItem('Email')}  </span> */}
                <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                        localStorage.clear();
                        alert('See you soon Admin, Bye 😊')
                        navigate('/sign-in')
                    }}
                >Logout</Button>
            </div>
            <div>
                <Button
                    variant="contained"
                    // color="success"
                    style={{
                        marginTop: '20px',
                        marginLeft: '40px'
                    }}
                    onClick={sayHello}>
                    ADD News
                </Button>
            </div>
            <div style={{ marginTop: '-65px' }}>
                <h1 style={{ textAlign: 'center', color: '#044382' }}>NEWS</h1>
                <ShowData />
            </div>
        </>
    )
}
import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";

export function Signin() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const handleSubmit = () => {
        // console.log(email, password);
        // axios.post('http://localhost:5000/sign-in', {
        axios.post('https://plum-combative-perch.cyclic.app/sign-in', {
            email: email,
            password: password
        })
            .then(res => {
                // console.log(res.data);
                if (res.data.code === 500) {
                    alert('sign in failed! User not found');
                }
                if (res.data.code === 404) {
                    alert('sign in failed! Password is wrong');
                }
                if (res.data.code === 200) {
                    alert('welcome back Admin 😁')
                    navigate('/');
                    localStorage.setItem('TOKEN', res.data.token)
                    localStorage.setItem('Email', res.data.email)
                    localStorage.setItem('Name', res.data.name)
                }
            }).catch(err => {
                // console.log(err);
            })
    }
    return (<>
        <h1 className="center"style={{color: '#044382', marginTop:'150px'}}>Sign in</h1>
        <div className="outcard">
            <label>Email</label><br />
            <input
                onChange={(e) => {
                    setEmail(e.target.value)
                }}
                value={email}
                className="inputs"
                type="email"
            /><br />
            <label>Password</label><br />
            <input
                onChange={(e) => {
                    setPassword(e.target.value)
                }}
                value={password}
                className="inputs"
                type="password"
            /><br />
            <br />
            <button
                onClick={handleSubmit}
                className="btns"
            >Sign in</button>
            <br /><br />
            {/* <Link style={{ textAlign: 'center', display: 'block', marginTop: '5px' }} to={'/sign-up'}>create an accout?</Link> */}
            {/* <Link style={{ textAlign: 'right', display: 'block', marginTop: '5px' }} to={'/forget-password'}>Forget Password?</Link> */}
        </div>
    </>
    )
}
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import Logo from '../assets/logo.png'

function Update() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [values, setValues] = useState({
        category: '',
        title: '',
        description_0: '',
        description_1: '',
        description_2: '',
        image: ''
    })

    const fetchData = () => {
        // return fetch(`http://localhost:5000/show-data/${id}`)
        return fetch(`https://plum-combative-perch.cyclic.app/show-data/${id}`)
            .then((response) => response.json())
            .then((data) => {
                // console.log("data", data.allTasks)
                setValues(data.allTasks)
            });
    }
    useEffect(() => {
        fetchData();
    }, [])
    function convertToBase64(e) {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
            // console.log(reader.result); //base64encoded string
            setValues({ ...values, image: reader.result })
        };
        reader.onerror = error => {
            // console.log("Error", error);
        };
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        // axios.put(`http://localhost:5000/update/${id}`, values)
        axios.put(`https://plum-combative-perch.cyclic.app/update/${id}`, values)
            .then(res => {
                // console.log(res.data)
            }).catch(err => {
                // console.log(err);
            })
        alert("data is been updated..! redirecting to home page..!")
        navigate('/')
    }
    function goback() {
        navigate('/')
    }
    return (
        <>
            <div className="card">
                <div style={{ margin: '10px' }}>
                    <Link style={{ textAlign: 'right', display: 'block', marginTop: '5px' }} to={'/'}>
                        <img src={Logo} style={{ width: '150px', marginTop:'-30px' }} />
                    </Link>
                </div>
                <span style={{ marginTop: '7px', fontSize: '30px', color: '#044382' }}>Hello  ' {localStorage.getItem('Name')} '</span>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                        localStorage.clear();
                        alert('See you soon Admin, Bye 😊')
                        navigate('/sign-in')
                    }}
                >Logout</Button>
            </div>
            <Button
                variant="outlined"
                color="error"
                style={{
                    marginTop: '50px',
                    marginLeft: '100px'
                }}
                onClick={goback}>
                Cancel / Go Back
            </Button>
            <h1 className="center" style={{ color: '#044382' }}>Update News</h1>
            <div className="outcard">
                <label>Categories</label><br />
                <input
                    name="category"
                    className="inputs"
                    type="text"
                    value={values.category}
                    onChange={e => setValues({ ...values, category: e.target.value })}
                /><br />
                <label>Title</label><br />
                <input
                    name='title'
                    className="inputs"
                    type="text"
                    value={values.title}
                    onChange={e => setValues({ ...values, title: e.target.value })}
                /><br />
                <label>Description Paragraph 1</label><br />
                <textarea
                    name='description'
                    className="inputs"
                    type="text"
                    value={values.description_0}
                    onChange={e => setValues({ ...values, description_0: e.target.value })}
                /><br />
                 <label>Description Paragraph 2</label><br />
                <textarea
                    name='description'
                    className="inputs"
                    type="text"
                    value={values.description_1}
                    onChange={e => setValues({ ...values, description_1: e.target.value })}
                /><br />
                 <label>Description Paragraph 3</label><br />
                <textarea
                    name='description'
                    className="inputs"
                    type="text"
                    value={values.description_2}
                    onChange={e => setValues({ ...values, description_2: e.target.value })}
                /><br />
                <label>Upload Image</label><br />
                <input
                    className="inputs"
                    type="file"
                    accept="image/*"
                    onChange={convertToBase64}
                    name='image'
                />
                <br />
                {values == "" || values == null ? "" : <img style={{ marginLeft: "270px" }} width={150} height={110} src={values.image} />}
                <br /><br />
                <button
                    onClick={handleSubmit}
                    className="btns"
                >Update</button>
                <br /><br />
            </div>
        </>
    )
}

export default Update
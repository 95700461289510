import React from "react";

export default function NewSubmit() {
    return (<>
        <h1 className="center">Submit New Password</h1>
        <div className="outcard">
            <label>OTP:</label><br />
            <input className="inputs"
                type="text"
            />
            <br />
            <label>New Password:</label><br />
            <input className="inputs"
                type="password"
            />
            <br /><br />
            <button className="btns"> CHANGE PASSWORD </button>
        </div >
    </>
    )
}